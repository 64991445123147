
import { mapState, mapMutations } from 'vuex'
import YoutubeUtils from './youtube-utils'
export default {
  name: 'YoutubePlayer',
  props: {
    videoId: {
      type: String,
      required: true,
      default: '',
    },
  },
  data: () => ({
    playing: false,
    player: null,
    videoExceptionId: null,
    timeoutRef: null,
    videoSize: { width: '100%', height: '100%' },
  }),
  computed: {
    ...mapState('behaviour', {
      videoBeingPlayedId: 'videoBeingPlayedId',
    }),

    id() {
      return `youtube-${Date.now()}-${Math.floor(Math.random() * 1000)}`
    },
  },
  watch: {
    videoBeingPlayedId(newVal) {
      if (newVal !== this.videoExceptionId) {
        if (this.player && this.videoStopCounter !== 0) {
          try {
            this.player.pauseVideo()
          } catch (e) {}
        }
      }
    },
  },

  async mounted() {
    const width = this.$refs.wrapper.offsetWidth
    const height = Math.round((width / 16) * 9)

    this.videoSize = {
      width: `${width}px`,
      height: `${height}px`,
    }

    await YoutubeUtils.getYoutubePlayerRef()

    // eslint-disable-next-line no-undef
    this.player = new YT.Player(this.id, {
      width,
      height,
      videoId: this.videoId,
      playerVars: {
        playsinline: 1,
      },
      events: {
        onReady: this.onPlayerReady.bind(this),
        onStateChange: this.onPlayerStateChange.bind(this),
      },
    })

    window.addEventListener('resize', this.adjustVideoPlayerSize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustVideoPlayerSize)
  },
  methods: {
    ...mapMutations('behaviour', {
      pauseAllVideos: 'pauseAllVideos',
    }),
    adjustVideoPlayerSize() {
      if (this.$refs.wrapperClone) {
        const width = this.$refs.wrapperClone.offsetWidth
        const height = Math.round((width / 16) * 9)

        this.videoSize = {
          width: `${width}px`,
          height: `${height}px`,
        }

        const youtubeIframeRef = document.querySelector(`#${this.id}`)

        if (youtubeIframeRef) {
          youtubeIframeRef.style.width = this.videoSize.width
          youtubeIframeRef.style.height = this.videoSize.height
        }
      }
    },
    onPlayerReady(event) {
      this.adjustVideoPlayerSize()
      this.player = event.target
    },
    onPlayerStateChange(event) {
      /**
       * This is the only way we can allow YT scrubbing and not pause
       * the video immediately and show banner
       *
       * YT has no way of providing a proper event when scrubbing through video
       * and sends out PAUSE event regardless of user action being seek +/-
       */
      clearTimeout(this.timeoutRef)
      this.timeoutRef = setTimeout(() => {
        const playerState = event.target.getPlayerState()
        if (
          // eslint-disable-next-line no-undef
          playerState === YT.PlayerState.ENDED ||
          // eslint-disable-next-line no-undef
          playerState === YT.PlayerState.PAUSED
        ) {
          this.$emit('stopped')
          this.playing = false
        }

        if (
          // eslint-disable-next-line no-undef
          playerState === YT.PlayerState.PLAYING
        ) {
          this.$emit('playing')
          this.playing = true
        }
      }, 1000)
    },
    onClickedPlay() {
      if (this.player) {
        if (this.videoBeingPlayedId !== this.videoExceptionId) {
          this.videoExceptionId = new Date().getTime()
          this.pauseAllVideos(this.videoExceptionId)
        }
        try {
          this.player.playVideo()
          this.$emit('initiated')
          this.playing = true
          this.$emit('playing')
        } catch (e) {}
      }
    },
  },
}
