let wasInitialized = false
let promise = null

const createYoutubeScript = () => {
  const tag = document.createElement('script')
  tag.src = 'https://www.youtube.com/iframe_api'
  const firstScriptTag = document.getElementsByTagName('script')[0]
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
}

const getYoutubePlayerRef = () => {
  if (!wasInitialized) {
    wasInitialized = true

    promise = new Promise((resolve) => {
      window.onYouTubeIframeAPIReady = () => {
        resolve()
      }
      createYoutubeScript()
    })
  }

  return promise
}

export default {
  getYoutubePlayerRef,
}
